@if (preferences) {

  <div
    class="dock dock-{{direction}} dock-{{isMobile?'bottom': position || 'start'}}">
    <div class="dock-item">

      <atw-image
        (click)="goToHome()"
        type="atwork"
        style="width:36px;height:36px;min-height:36px;margin-top:6px;cursor:pointer;"
        [style.margin-top]="position=='bottom' ?0:'6px'"
        [style.margin-inline-start]="position!='bottom' ?0:'6px'"
      />
<!--      <img src="assets/media/yalda.png" class="logo-addition-right"/>-->
    </div>


    <p-divider [layout]="position =='bottom'?'vertical':'horizontal'"/>

    <div cdkDropList [cdkDropListOrientation]="position=='bottom'?'horizontal':'vertical'"
         [cdkDropListLockAxis]="position=='bottom'?'x':'y'"
         id="tour_workspaces"
         [cdkDropListData]="items" (cdkDropListDropped)="changeSort($event)"
         class="all-spaces-holder">
          <span [pTooltip]="'workspaces'|translate" appendTo="body"
                (click)="onLaunchpadClick();$event.stopPropagation()"
                class="dock-item cursor-pointer  border-radius opacity-100-hover mb-2">
              <img src="assets/svgs/appstore.svg" width="24" height="24">
            </span>

      @for (item of items; track $index) {

        @if (item.obj) {

          <icon cdkDrag [icon]="item.obj.icon" size="xlarge"
                [class.dock-item-active]="item.id == currentItem"
                [routerLink]="item.type=='space' ? '/panel/space/'+item.id+'/home' : '/panel/app/'+item.obj['path']"
                class="dock-item cursor-pointer"
                [pTooltip]="item.obj.name" appendTo="body"
          />
        }

      }
      @if (tempItem) {

        <p-divider [layout]="position =='bottom'?'vertical':'horizontal'"/>


        <icon [icon]="tempItem.obj.icon" size="xlarge"
              [class.dock-item-active]="tempItem.id == currentItem"
              routerLink="/panel/space/{{tempItem.id}}/home"
              class="dock-item cursor-pointer"
              [pTooltip]="tempItem.obj.name" appendTo="body"
        />
      }

    </div>

    <p-divider [layout]="position =='bottom'?'vertical':'horizontal'"/>

    <div class="setting-holder">
      <icon
        icon="svg:com012"
        [badge]="chatBadge"
        [active]="focusArea == 'chat'"
        size="xlarge"
        class="dock-item setting-item"
        (click)="messages()"
        [pTooltip]="'INBOX'|translate"
        appendTo="body"
        routerLink="/panel/chat"
        routerLinkActive="orange"
        [ngClass]="focusArea == 'chat' ? 'dock-item-active' : ''"
      />

      <icon
        *ngIf="isOwner"
        icon="svg:cod001"
        [active]="focusArea == 'setting'"
        size="xlarge"
        class="dock-item setting-item"
        (click)="administration()"
        pTooltip="{{'ADMINISTRATION' | translate }}"
        appendTo="body"
        [ngClass]="focusArea == 'setting' ? 'dock-item-active' : ''"
      />
    </div>

  </div>
}
