@if (currentSpace) {
  <div style="overflow: hidden; display: flex; flex-flow: row; height: 100%" id="tour_space_items">

    <div class="space-holder">
      @if (showTitles) {
        <div
          class="space-title"
          #dropdown="ngbDropdown"
          ngbDropdown
          container="body"
          routerLink="/panel/space/{{currentSpace.id}}/home"
          placement="bottom-end"
          [style.justify-content]="showTitles ? 'space-between' : 'center'"
        >
          <div class="item-left" (contextmenu)="openContextMenu(dropdown)">
            <inline-title
              [icon]="currentSpace.icon"
              [hideText]="!showTitles"
              size="xlarge"
              [fontWeight]="600"
              [class.ellipsis]="!currentRenaming"
              class="ellipsis"
              [title]="currentSpace.name"
              [editable]="currentRenaming"
              (submit)="onRename($event)"
              [ngbTooltip]="showTitles ? '' : currentSpace.name"
              container="body"
              color="var(--orange)"
            />
          </div>
          <div class="item-right" ngbDropdownAnchor [hidden]="!showTitles || currentRenaming">
            <!--            <icon-->
            <!--              icon="bi-journal-plus"-->
            <!--              size="large"-->
            <!--              (click)="onCreateSheetClick(); $event.stopPropagation()"-->
            <!--              ngbTooltip="{{'ADD_NEW_WORKSHEET' | translate }}"-->
            <!--              placement="bottom-end"-->
            <!--              container="body"-->
            <!--              [openDelay]="500"-->
            <!--            ></icon>-->

            <!--            <icon-->
            <!--              icon="bi-folder-plus"-->
            <!--              size="large"-->
            <!--              ngbTooltip="{{'NEW_FOLDER' | translate }}"-->
            <!--              placement="bottom-end"-->
            <!--              container="body"-->
            <!--              [openDelay]="500"-->
            <!--              (click)="onCreateFolderClick(); $event.stopPropagation()"-->
            <!--            ></icon>-->

            <icon
              icon="bi-three-dots-vertical"
              size="large"
              class="more-icon"
              [hoverable]="true"
              (click)="dropdown.open(); $event.stopPropagation()"
            />

          </div>
          <div ngbDropdownMenu>
            <div
              *ngIf="!currentDeleting"
              class="dropdownitem"
              (click)="onCreateTaskSheetClick(); dropdown.close()"
            >
              <icon icon="bi-list-task"></icon>
              {{ "ADD_NEW_TASKSHEET" | translate }}
            </div>
            <div
              *ngIf="!currentDeleting"
              class="dropdownitem"
              (click)="currentRenaming = true; dropdown.close()"
            >
              <icon icon="bi-pencil"></icon>
              {{ "RENAME" | translate }}
            </div>
            <div
              *ngIf="!currentDeleting"
              class="dropdownitem"
              (click)="showPermissionDialog(); dropdown.close()"
            >
              <icon icon="bi-shield-lock"></icon>
              {{ "PERMISSIONS" | translate }}
            </div>
            <div
              *ngIf="!currentDeleting && environment.exportDomains.includes(domain||'')"
              class="dropdownitem"
              (click)="showExportDialog(); dropdown.close()"
            >
              <icon icon="bi-box-arrow-up"></icon>
              {{ 'EXPORT' | translate }}
            </div>
            <div *ngIf="!currentDeleting" class="dropdownitem" (click)="currentDeleting = true">
              <icon icon="bi-folder-x"></icon>
              {{ "delete" | translate }}
            </div>
            <div *ngIf="currentDeleting" class="confirm-delete-holder">
              <div class="confirm-title">{{ "areYouSure?" | translate }}</div>
              <div class="confirm-delete-button-holder">
          <span
            class="confirm-yes"
            (click)="currentDeleting = false; dropdown.close(); deleteCurrentSpace()"
          >{{ "delete" | translate }}</span
          >
                <span class="confirm-no" (click)="currentDeleting = false; dropdown.close()">{{
                    "cancel" | translate
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr>
      }

      @if (isTreeLoaded) {

        <app-tree-folder
          class="space-items"
          [showTitles]="showTitles"
          [folder]="tree"
          [currentItem]="activeSheetId"
          [currentSpaceId]="currentSpace.id"
          (onMove)="onItemMove($event)"
        />
      } @else {
        <div class="d-flex flex-column gap-2">
          @for (i of [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; track $index) {
            <div class="shimmer border-radius w-100 h-30px"></div>
          }
        </div>
      }


      @if (showTitles && isTreeLoaded) {

        <div class="d-flex mt-4 justify-content-center">

          <div class="btn-dropdown">
            <div (click)="onCreateSheetClick()" class="min-w-125px text-align-center">{{ 'NEW_WORKSHEET' | translate }}</div>

            <div ngbDropdown class="align-content-center" container="body">
              <icon ngbDropdownToggle icon="bi-chevron-down"/>

              <div ngbDropdownMenu>
                <div
                  class="d-flex"
                  ngbDropdownItem
                  (click)=" onCreateFolderClick()"
                >
                  <icon icon="bi-folder-plus"></icon>
                  <span> {{ "NEW_FOLDER" | translate }} </span>
                </div>

                <div
                  ngbDropdownItem
                  class="d-flex"

                  (click)=" onCreateSheetClick()"
                >
                  <icon icon="bi-journal-plus"></icon>
                  <span> {{ "NEW_WORKSHEET" | translate }} </span>
                </div>

                <div
                  ngbDropdownItem
                  class="d-flex"

                  (click)="  onCreateTaskSheetClick()"
                >
                  <icon icon="bi-list-task"></icon>
                  <span> {{ "NEW_TASKSHEET" | translate }} </span>
                </div>
                <div
                  ngbDropdownItem
                  class="d-flex"

                  (click)="  onCreatePageClick()"
                >
                  <icon icon="bi-blockquote-left"></icon>
                  <span> {{ "new_page" | translate }} </span>
                </div>

                <div
                  ngbDropdownItem
                  class="d-flex"

                  (click)=" importCsvFile()"
                >
                  <icon icon="bi-file-earmark-arrow-down"></icon>
                  <span> {{ "IMPORT_CSV" | translate }} </span>
                </div>
                <div
                  ngbDropdownItem
                  class="d-flex"

                  (click)=" openMarketplace()"
                >
                  <icon icon="bi-shop-window"></icon>
                  <span> {{ "IMPORT_TEMPLATE" | translate }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--        <div class="create-item-holder">-->
          <!--          <div class="create-item-title" (click)="createExpanded = !createExpanded">-->
          <!--            {{ "CREATE_NEW_ITEM" | translate }}-->
          <!--            <icon-->
          <!--              icon="bi-chevron-down"-->
          <!--              class="create-item-anchor"-->
          <!--              [ngClass]="createExpanded ? 'create-item-anchor-rotate' : ''"-->
          <!--            ></icon>-->
          <!--          </div>-->
          <!--          <div class="create-item-list" [ngClass]="createExpanded ? 'create-item-list-active' : ''">-->
          <!--           -->
          <!--          </div>-->
          <!--        </div>-->
      }

    </div>

  </div>
}
